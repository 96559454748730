// Header
.header {
  color: $color10;
  position: fixed;
  z-index: 1500;
  top: 0;
  left: 0;
  width: 100%;
  height: rem(85);
  display: block;
  transition: all .4s ease;

  * {
      user-select: none;
  }

  &.-sticky {
      background-color: $color25;
  }

  .brand {
      float: left;
      display: inline-block;
      width: rem(90);
      line-height: rem(85);
      position: relative;

      .logo-text {
          color: $color10;
          font-size: rem(18);
          font-weight: 700;
          line-height: rem(85);
          white-space: nowrap;
      }
  }

  &-toggle {
      margin-top: rem(18);
      padding: 0;
      border: none;
      outline: none;
      width: rem(24);
      height: rem(28);
      float: right;
      cursor: pointer;
      background-color: transparent;
      position: relative;

      .bar {
          top: rem(6);
          left: 0;
          position: absolute;
          transform: translateY(rem(10));
          transition: all 0ms 300ms;
          background-color: rgba($color10, 1);

          &, &:after, &:before {
              border-radius: rem(2);
              width: rem(24);
              height: rem(2);
          }

          &:before {
              content: '';
              position: absolute;
              left: 0;
              bottom: rem(8);
              background-color: rgba($color10, 1);
              transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
          }

          &:after {
              content: '';
              position: absolute;
              left: 0;
              top: rem(8);
              background-color: rgba($color10, 1);
              transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
          }
      }

      &.-active {
          .bar {
              background: rgba($color10, 0);
              &:after {
                  top: 0;
                  transform: rotate(45deg);
                  transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);;
              }
              &:before {
                  bottom: 0;
                  transform: rotate(-45deg);
                  transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);;
              }
          }
      }
  }

  @media screen and (min-width: $screen-lg - 1) {

      &-toggle {
          display: none;
      }
  }

  @media screen and (max-width: $screen-lg - 1) {
      .brand {
          width: rem(100);

      }
  }

  @media screen and (max-width: $screen-lg - 1) {
      height: rem(70);
      background-color: $color25 !important;
      background-image: none;

      &-bar {
          padding-left: rem(10);
          padding-right: rem(10);
      }

      .brand {
          width: rem(80);
          line-height: rem(75);
      }
  }
}
