// Card
.card {
  // OVERLAYS
  &.-overlay{
    &-granuled {
      &:before {
        content: '';
        position: absolute;
        background-image: url(../images/006.png);
        background-size: cover;
        background-attachment:initial;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }
    }
  }
  
  // CARDS
  &.-featured {
    height: 70vh;
    padding-top: rem(135);
    .card-caption {
      .title {
        font-size: rem(75);
        color: $color10;
        font-family: $poppins;
        margin-bottom: rem(10);
      }
      .link {
        color: $color10;
      }
    }
  }

  &.-speaker {
    margin-bottom: rem(30);

    .card-figure {
      height: rem(200);
      background-color: gray;
      border-radius: rem(5);
      border-bottom-left-radius: rem(0);
      border-bottom-right-radius: rem(0);
      overflow: hidden;
      position: relative;

      // &:before {
      //   content: '';
      //   position: absolute;
      //   bottom: 0px;
      //   left: 0;
      //   width: 100%;
      //   height: rem(150);
      //   background-image: linear-gradient(to bottom, transparent 10%, white 90%);
      //   z-index: 2;
      // }
    }
    .card-caption {
      color: $color28;
      padding: rem(20);
      background-color: $color10;
      height: rem(180);
      border-bottom-left-radius: rem(5);
      border-bottom-right-radius: rem(5);
      position: relative;
      text-align: center;
      box-shadow: 0px 4px 17px 0px rgba(0,0,0,0.1);
    
      .title {
        font-size: rem(20);
        font-weight: 600;
        margin-bottom: rem(10);
      }
      .legend {
        text-transform: uppercase;
        font-size: rem(13);
        letter-spacing: rem(1);
      }
    }
    .-social {
      display: inline-block;
      position: absolute;
      bottom: rem(-27);
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      text-align: center;
      // margin-left: rem(20);
    }
  }

  &.-partner {
    margin-bottom: rem(20);
    .link {
      z-index: 2;
    }
    .card-figure {
      height: rem(150);
    }
  }

  &.-notice {
    margin-bottom: rem(40);
    .link.-block {
      z-index: 2;
    }
    &:hover {
      .card-content {
        box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.2);
        background-color: rgba($color10, 1);
      }
      .card-caption {
        .title:before {
          width: rem(150);
        }
      }
    }
    .card-content {
      transition: all ease .3s;
      padding: rem(45);
      background-color: rgba($color10, .7);
      border-radius: rem(5);
      box-shadow: 0px 5px 15px 0px rgba(0,0,0,0.1);
    }
    .card-caption {
      .date {
        font-weight: 700;
        text-transform: uppercase;
        color: $color20;
        font-size: rem(13);
        letter-spacing: rem(1);
        margin-bottom: rem(20);
        display: block;
      }
      .title {
        font-weight: 700;
        margin-bottom: rem(30);
        color: $color25;
        position: relative;

        &:before {
          content: '';
          transition: all ease .3s;
          position: absolute;
          width: rem(100);
          height: rem(2);
          background-color: $color20;
          bottom: rem(-15);
          left: rem(0);
        }
      }
      .legend {
        font-weight: 500;
      }
    }
  }

  &.-date {
    &.-mini {
      position: relative;
      border: rem(2) dashed $color25;
      padding: rem(8 8 5);
      display: inline-block;
      border-radius: rem(5);
      margin-right: rem(15);
      margin-bottom: rem(15);
      color: $color25;

      .card-date {
        display: inline-block;
        text-align: center;
        font-weight: 700;
        line-height: 1;

        .day {
          display: block;
          font-size: rem(24);
          letter-spacing: rem(2);
        }
        .month {
          display: block;
          font-size: rem(13);
        }
      }
      .card-caption {
        display: inline-block;
        vertical-align: top;
        line-height: 1.3;
        padding-left: rem(5);
        .text {
          margin-bottom: 0;
          font-size: rem(14);
          letter-spacing: rem(1);
          font-weight: 600;
        }
        .hour-init,
        .hour-end {
          display: block;
        }
      }
    }
  }

  &.-people {
    &.-big {
      display: block;
      margin-bottom: rem(20);
      margin-right: rem(10);
      min-height: rem(100);
      .card-figure {
        width: 200px;
        height: 169px;
        display: inline-block;
        border-radius: 5px;
        overflow: hidden;
      }
      .card-caption {
        padding: rem(15);
        color: $color25;
        display: inline-block;
        vertical-align: top;
        font-size: rem(18);
        .text {
          margin-bottom: 0;
        }
        .name {
          font-size: 24px;
          margin-bottom: rem(5);
          font-weight: 600;
          letter-spacing: rem(2);
        }
      }      
    }
    &.-mini {
      display: inline-block;
      margin-bottom: rem(10);
      margin-right: rem(10);
      min-height: rem(100);
      .card-figure {
        width: 100px;
        height: 80px;
        display: inline-block;
        border-radius: 5px;
        overflow: hidden;
      }
      .card-caption {
        padding: rem(5);
        color: $color25;
        display: inline-block;
        vertical-align: top;
        font-size: rem(14);
        .text {
          margin-bottom: 0;
        }
        .name {
          font-size: rem(14);
          margin-bottom: rem(5);
          font-weight: 600;
          letter-spacing: rem(2);
        }
      }
    }
  }
}
