.control {
    .price {
        font-size: rem(15);
        letter-spacing: rem(1);
        color: $color36;
        margin-top: rem(5);
    }
    .calc {
        height: rem(35);
        position: relative;
        display: inline-block;
        width: 90px;

        .btn {
            width: rem(21);
            display: block;
            transition: all ease .2s;
            height: rem(21);
            padding: 0;
            color: $color36;
            border: 1.2px solid $color36;
            border-radius: rem(30);
            background-color: transparent;
            display: inline-block;
            position: absolute;
            top: 0;

            &:hover {
                border: 1.2px solid $color20;
                color: $color20;
            }
            
            &.-less {
                left: 0;
            }
            
            &.-plus {
                right: 0;
            }

            &:before {
                vertical-align: middle;
                font-size: rem(12);
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }

        .input {
            border: none;
            width: auto;
            display: inline-block;
            width: calc(100% - 60px);
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            color: $color36;
            text-align: center;
            &:focus {
                border: none;
            }
        }
    }
    .buy {
        margin-top: rem(10);
    }
}