// Align
.align {
  display: table;
  height: 100%;
  margin: 0 auto;
    &-middle {
      display: table-cell;
      vertical-align: middle;
    }
  }
  