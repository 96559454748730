// Padding
.p {
    &l {
        &-15 {
            padding-left: rem(15) !important;
        }
        &-30 {
            padding-left: rem(30) !important;
        }
        &-40 {
            padding-left: rem(40) !important;
        }
        &-50 {
            padding-left: rem(50) !important;
        }
        &-60 {
            padding-left: rem(60) !important;
        }
    }
    &b {
        &-0 {
            padding-bottom: rem(0) !important;
        }

        &-15 {
            padding-bottom: rem(15) !important;
        }
        &-30 {
            padding-bottom: rem(30) !important;
        }
        &-40 {
            padding-bottom: rem(40) !important;
        }
        &-60 {
            padding-bottom: rem(60) !important;
        }
    }
    &t {
        &-0 {
            padding-top: rem(0) !important;
        }

        &-15 {
            padding-top: rem(15) !important;
        }
        &-30 {
            padding-top: rem(30) !important;
        }
        &-40 {
            padding-top: rem(40) !important;
        }
        &-60 {
            padding-top: rem(60) !important;
        }
    }

    &-0 {
        padding: 0 !important;
    }
    &-5{
    	padding: rem(5) !important;
    }
    &-10{
    	padding: rem(10) !important;
    }
    &.-boxed {
	    padding-top: rem(70);
        padding-bottom: rem(70);
    }
    &-40{
    	padding: rem(40);
    }
}
