.label {
    padding: rem(3 5);
    font-size: rem(11);
    text-transform: uppercase;
    border-radius: rem(3);

    &.-primary {
        background-color: $color20;
        color: $color10;
    }
}