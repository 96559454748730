.cronogram {
    .table {
        position: relative;
        margin-left: rem(10);
        &:before {
            content: '';
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            height: calc(100% - 75px);
            width: 1px;
            background-color: $color10;
        }
        .list{}
        .item{
            position: relative;
            display: block;
            padding: rem(30 0) !important;
            border-bottom: rem(1) solid $color10;

            &:before {
                content: '';
                position: absolute;
                left: rem(-7.5);
                top: 50%;
                transform: translateY(-50%);
                width: rem(16);
                height: rem(16);
                border-radius: rem(50);
            }

            &:last-child {
                border-bottom: none;
            }
            &.-finished{
                &:before {
                    background-color: $danger;
                }
            }
            &.-current{
                .event,
                .date {
                    opacity: 1;
                }

                &:before {
                    background-color: $success;
                }
            }
            &.-future{
                &:before {
                    background-color: $warning;
                }
            }
        }
        .event,
        .date {
            color: $color10;
            font-size: rem(14);
            letter-spacing: rem(1);
            text-transform: uppercase;
            font-weight: 600;
            opacity: .6;
        }
        .event {
            padding-left: rem(30);
        }
        .date {
            text-align: right;
            font-weight: 600;
            font-size: rem(16);
        }
    }
    .legend {
        .item{
            position: relative;
            display: inline-block;
            margin-right: rem(20);
            margin-left: rem(3);
            padding-left: rem(25);
            color: $color10;
            
            .text {
                text-transform: uppercase;
                font-size: rem(12);
                letter-spacing: rem(2);
                font-weight: 700;
            }

            &:before {
                content: '';
                position: absolute;
                left: rem(0);
                top: 40%;
                transform: translateY(-50%);
                width: rem(16);
                height: rem(16);
                border-radius: rem(50);
            }

            &.-finished{
                &:before {
                    background-color: $danger;
                }
            }
            &.-current{
                &:before {
                    background-color: $success;
                }
            }
            &.-future{
                &:before {
                    background-color: $warning;
                }
            }
        }
    }
    @media screen and (max-width: $screen-xs-max){
        .table .date {
            text-align: left;
            margin-top: 10px;
            margin-left: 30px;
            font-weight: 500;
        }
        .legend {
            .item{
                position: relative;
                display: inline-block;
                margin-right: rem(20);
                margin-left: rem(3);
                padding-left: rem(25);
                color: $color10;
                
                .text {
                    text-transform: uppercase;
                    font-size: rem(12);
                    letter-spacing: rem(2);
                    font-weight: 700;
                }
    
            }
        }
    }
}