.parallax {
    &-wrapper {
        .-newsletter {
            position: absolute;
            &.-one{
                width: rem(400);
                height: rem(700);
                bottom: rem(-120);
                left: rem(-60);
            }
            &.-two {
                width: rem(300);
                height: rem(300);
                top: rem(-80);
                right: rem(-100);
            }
            &.-three {
                width: rem(300);
                height: rem(400);
                bottom: -162px;
                right: 58px;
            }
        }   
    }
}