// Footer
.footer {
  padding: rem(50 0 50);
  text-align: center;
  font-size: rem(12);
  background-color: $color41;
  .link {
    color: $color10;
  }

  &-copyright {
    text-transform: uppercase;
    color: $color10;
    letter-spacing: rem(1);
    margin-bottom: rem(20);
  }
  
  &-author {
    text-align: right;
    .brz {
      width: rem(70);
      position: relative;
      display: inline-block;

      .image {
          width: 100%;
      }
    }
  }
}
