// Icons
$icons: (
  down:         'a',
  right:        'b',
  up:           'c',
  left:         'd',
  plus:         'e',
  less:         'f',
  whatsapp:     'g',
  google:       'h',
  facebook:     'i',
  instagram:    'j',
  linkedin:     'k',
  youtube:      'l',
  twitter:      'm',
  lattes:       'n',
  mail:         'o',
  home:         'p',
  heart:        'q',
  phone:        'r',
  star:         's',
  calendar:     't'
);
