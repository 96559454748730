// List
.list {
    margin: 0;
    padding: 0;
    list-style: none;
    
    .item {
        margin: 0;
        padding: 0;
        display: block;
    }

    &.-inline {
        .item {
            display: inline-block;
        }
    }

    &.-attrs {
        text-transform: uppercase;
        font-weight: 700;
        color: $color25;
        letter-spacing: rem(2);
        font-size: rem(13);
    }

    &.-social {
        .item {
            margin-bottom: rem(5);
        }
        .link {
            color: $color20;
            font-size: rem(20);

            &:hover {
                color: $color25;
            }
        }

        &.-default {
            .link {
                color: $color10;
                font-size: rem(20);
    
                &:hover {
                    color: $color20;
                }
            }
        }

        &.-terciary {
            .link {
                color: $color36;
    
                &:hover {
                    color: $color20;
                }
            }
        }
        
        &.-inline {
            text-align: left;
            .item {
                margin-right: rem(10);
                display: inline-block; 

                &:last-child {
                    margin-right: rem(0);
                }
            }
        }

        &.-colors {
            text-align: center;
            .item {
                margin-right: rem(0);
                float: none;
                display: inline-block;
                text-align: center;
            }
            .link {
                position: relative;
                width: rem(40);
                height: rem(40);
                display: inline-block;
                border-radius: rem(50);
                background-color: $color28;
                color: $color10;

                &.icon-facebook-before {
                    background-color: $facebook;
                }
                &.icon-twitter-before {
                    background-color: $twitter;
                }
                &.icon-instagram-before {
                    background-color: $instagram;
                }

                &:hover {
                    transform: translateY(-6px);
                }

                &:before {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    font-size: rem(16);
                }
            }
        }
    }
}
