// Form
@import "../tools/_custom_placeholder.scss";

.form {
    .input,
    .textarea,
    .select {
        border: none;
        border-bottom: rem(2) solid $color15;
        border-radius: 0;
        padding: 0;
        background-color: transparent;
        outline: none;
        box-shadow: none;
        letter-spacing: rem(1);
        color: $color10;

        @include placeholder {
            color: $color15;
            text-transform: uppercase;
            letter-spacing: rem(1);
            font-size: rem(13);
        }
        
        &:focus {
            border-bottom: rem(2) solid $color20;
        }
        @media screen and (max-width: $screen-sm-max) {

        }
    }

    &.-white {
        color: $color10;
        .input,
        .textarea,
        .select {
            border: none;
            border-bottom: rem(2) solid $color10;
            resize: none;
            
            option {
                color: $color19;
            }
    
            @include placeholder {
                color: $color15;
            }
            
            &:focus {
                border-bottom: rem(2) solid $color20;
            }

            @media screen and (max-width: $screen-sm-max) {
    
            }
        }
    }

    &.-subscribe {
        .block {
            margin-bottom: rem(50);
            &:last-child {
                margin-bottom: rem(0);    
            }
            .title {
                font-size: rem(20);
                font-weight: 600;
                margin-bottom: rem(30);
            }
        }
    }
    .label {
        padding: 0;
        font-weight: 600;
        letter-spacing: rem(1);
    }
    .help-block {
        display: block;
        color: $danger;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-size: 12px;
    }
    &.-boxed {
        background-color: $color25;
        color: $color10;
        padding: rem(50 60);
    }
}
