// Toggle
.toggle {
    display: block;
    width: rem(40);
    height: rem(40);
    position: absolute;
    border: none;
    outline: none;
    cursor: pointer !important;
    background-color: transparent;
    margin: auto;
    text-align: center;
    margin-top: rem(20);
    left: 0;

    .bar {
      top: 0;
      left: 0;
      position: absolute;
      transform: translateY(.625rem);
      transition: all 0ms .3s;
      background-color: rgba($color30, 1);
      border-radius: rem(2);
      width: rem(40);
      height: rem(2);
  
      &:before,
      &:after, {
        background-color: rgba($color30, 1);
        border-radius: rem(2);
        width: rem(40);
        height: rem(2);
      }
  
      &:before {
        content: 'MENU';
        font-size: rem(13);
        letter-spacing: rem(1);
        position: absolute;
        left: 0;
        top: rem(5);
        background-color: rgba($color30, 0);
      }
  
      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: rem(26);
        background-color: rgba($color30, 1);
        transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
      }
    }
  
    &.-active {
      margin-top: rem(30);
      .bar {
          color: transparent;
          background-color: rgba($color36, 0);
        &:after {
          top: rem(5);
          transform: rotate(45deg);
          transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
          background-color: rgba($color36, 1);
        }
  
        &:before {
          bottom: 0;
          transform: rotate(-45deg);
          transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
          background-color: rgba($color36, 1);
        }
      }
  
      @media screen and (max-width: $grid-float-breakpoint-max) {
        .bar {
          transform: translateY(rem(16));
        }
      }
    }
  }
  