.submenu {
    position: absolute;
    padding: rem(20);
    left: 0;
    min-width: rem(250);
    // min-width: rem(150);
    background-color: $color10;
    line-height: initial;
    transition: all .4s ease;
    opacity: 0;
    top: rem(60);
    color: $color10;
    visibility: hidden;
    box-shadow: rem(0 2 5 1) rgba($color20,0.23);


	&.show {
	    top: rem(85);
	    opacity: 1;
        visibility: inherit;
	}

	.subitem {
		display: block;
        line-height: 1;
        margin-bottom: rem(8);

        &:last-child {
            margin-bottom: 0;
        }
        .link {
            padding: 0;
            margin: 0;
            height: 100%;
            color: $color25;
            font-size: rem(14);
            transition: all .4s ease;
        }
        &:hover {
            .link {
                color: $color20;
            }
        }
	}

    @media screen and (max-width: $screen-md-max) {
        padding-left: rem(31);
        padding-top: 0;
        margin-top: rem(-8);
        box-shadow: none;
        top: inherit;
        background-color: transparent;
        margin-top: rem(0);
        padding-bottom: rem(5);

        &.show {
            position: relative;
            top: 0;
            display: block;

            .container {
                padding: 0;
            }
        }

        .subitem {
            padding: 0;
            width: 100%;
            margin-bottom: rem(5);

            .link {
                font-size: rem(12);
                padding-left: rem(10);
                line-height: 2.2;
                color: $color10;
                position: relative;

                &:before {
                    content: '';
                    position: absolute;
                    left: 0;
                    height: rem(1);
                    width: rem(5);
                    top: 50%;
                    background-color: $color20;
                    transform: translateY(-50%);
                }
            }
        }
    }
}
