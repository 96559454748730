// Button
.btn {
    font-size: rem(12);
    transition: all ease .3s;
    text-transform: uppercase;
    padding: rem(20 28);
    border-radius: 0;
    letter-spacing: rem(1);
    font-weight: 600;
    
    &.-block {
        display: block;
        width: 100%;
    }
    
    &.-flat {
        &.-primary{
            background-color: $color25;
            color: $color10;
            &:hover {
                background-color: $color28;
            }
        }

        &.-primary-b {
            background-color: $color20;
            color: $color10;
            &:hover {
                background-color: $color21;
            }
        }
        &.-secondary{}
        &.-terciary{
            &.-primary{
                background-color: $color33;
                color: $color10;
                &:hover {
                    background-color: $color36;
                }
            }
        }
    }

    &.-inline {
        &.-primary{
            border: 1.5px solid $color20;
            color: $color20;
            &:hover {
                border: 1.5px solid $color25;
                color: $color25;
            }
        }
        &.-secondary{}
        &.-terciary{}
    }

    &.-inline-flat {
        &.-primary{
            border: 1.5px solid $color20;
            color: $color20;
            &:hover {
                background-color: $color20;
                color: $color10;
            }
        }
        &.-secondary{}
        &.-terciary{}
    }
}
