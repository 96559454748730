// Fonts
$fontastic: 'netuno';
$poppins: 'poppins';
$abriltext: 'abriltext';

/* Fontastic */
@include font-face(
  $fontastic,
  "../fonts/fontastic/#{$fontastic}",
  normal,
  normal,
  eot woff ttf svg
);

/* POPPINS */
@include font-face(
  $poppins,
  "../fonts/poppins/Poppins-Light",
  300,
  normal,
  woff woff2
);

@include font-face(
  $poppins,
  "../fonts/poppins/Poppins-Regular",
  normal,
  normal,
  woff woff2
);

@include font-face(
  $poppins,
  "../fonts/poppins/Poppins-Semibold",
  600,
  normal,
  woff woff2
);

@include font-face(
  $poppins,
  "../fonts/poppins/Poppins-Bold",
  700,
  normal,
  woff woff2
);

/* ABRILTEXT */
@include font-face(
  $abriltext,
  "../fonts/abriltext/AbrilText-SemiBold",
  600,
  normal,
  woff woff2
);

@include font-face(
  $abriltext,
  "../fonts/abriltext/AbrilText-Regular",
  500,
  normal,
  woff woff2
);