// Icon
[class^="icon"],
[class*=" icon"] {
  &:before, &:after {
    font-family: $fontastic !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

// -icon-name-[after|before]
.icon {
  @each $key, $value in $icons {
    &-#{$key} {
      &-after:after, &-before:before {
        content: $value;
      }
    }
  }
}
