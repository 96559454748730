// Slide
.slide {
    position: relative;
    overflow: hidden;
  
    .slide-pagination {
      z-index: 999;
      position: absolute;
  
      &.-bullets {
        height: rem(20);
        line-height: rem(20);
  
        .swiper-pagination-bullet {
          display: inline-block;
          margin-left: rem(5);
          margin-right: rem(5);
          width: rem(5);
          height: rem(5);
          vertical-align: middle;
          background-color: $color10;
          transition: all ease .5s;
  
          &-active {
            width: rem(20);
            height: rem(5);
            border-radius: rem(2);
          }
  
          &:first-of-type {
            margin-left: 0;
          }
  
          &:last-of-type {
            margin-right: 0;
          }
        }
      }
  
      &.-horizontal {
        .swiper-button-prev,
        .swiper-button-next,
        .slide-counter {
          display: inline-block;
        }
  
        .swiper-button-prev,
        .swiper-button-next {
          width: rem(30);
        }
      }
  
      &.-vertical {
        .swiper-button-prev {
          margin-bottom: rem(20);
        }
  
        .swiper-button-prev,
        .swiper-button-next {
          display: block;
        }
      }
  
      .swiper-button-prev,
      .swiper-button-next {
        color: $color36;
        line-height: rem(20);
        transition: all .4s ease;
  
        &:before,
        &:after {
          display: block;
          width: rem(30);
          height: rem(20);
          font-size: rem(20);
          text-align: center;
        }
  
        &:hover {
          color: $color30;
        }
      }
    }
  
    @media screen and (min-width: $screen-sm) {
      &.swiper-only-mobile {
        .slide-pagination {
          display: none;
        }
      }
    }
  }
  
  @keyframes pagination {
    0% {
      transform: translateY(10%);
    }
  
    70% {
      transform: translateY(-10%);
    }
  
    100% {
      transform: translateY(10%);
    }
  }
  