// Post
.post {
    color: $color17 !important;
    margin-bottom: rem(30) !important;

    h1,h2,h3,h4,h5,h6 {
        margin-top: rem(5) !important;
        margin-bottom: rem(15) !important;
        font-weight: 800 !important;
        display: block !important;
        color: $color25 !important;
    }

    h1 { font-size: rem(25) !important; }
    h2 { font-size: rem(23) !important; }
    h3 { font-size: rem(20) !important; }
    h4 { font-size: rem(18) !important; }
    h5 { font-size: rem(16) !important; }
    h6 { font-size: rem(15) !important; }

    span,
    p {
      color: $color15 !important;
    }

    img {
        max-width: 100% !important;
        width: 100% !important;
        margin: rem(20 0);
    }

    ol,
    ul {
        margin-top: rem(10) !important;
        margin-bottom: rem(5) !important;
        li {
            margin-bottom: rem(5) !important;
        }
    }
    a {
        color: $color20;
    }

}
