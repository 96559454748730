.table {
  &.-prog {
    thead {
      background-color: $color25;
      color: $color10;
      font-weight: 700;

      th {
        padding: rem(20 10);
        
        .title {
            font-weight: 700;
        }
      }
    }
    tbody tr:nth-child(odd){
      background-color:rgba($color20, .2);
    }
    tbody tr:nth-child(even){
      background-color:rgba($color20, .1);
    }
  }
}