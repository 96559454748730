// Bootstrap variables
$text-color:                $color19 !default;
$link-color:                $color19 !default;
$link-hover-color:          $color19 !default;
$input-color-placeholder:   $color19 !default;
$font-size-base:            16px !default;
$font-family-base:          $poppins !default;

// Path framework
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/variables";
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins";
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/normalize";
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/print";
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/scaffolding";
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/type";
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/grid";
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/tables";
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/forms";
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/buttons";
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/component-animations";
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/dropdowns";
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/button-groups";
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/input-groups";
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/breadcrumbs";
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/pagination";
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/pager";
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/progress-bars";
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/media";
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/responsive-embed";
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/close";
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/modals";
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/tooltip";
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/utilities";
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/responsive-utilities";
