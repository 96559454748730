.title {
    &.-biggest{
        font-size: rem(40);
    }
    &.-medium{
        font-size: rem(30);
    }
    &.-lined {
        padding-left: rem(60);
        position: relative;
        margin-bottom: rem(30);
        line-height: 1.5;
        &:before {
            content: '';
            position: absolute;
            top: rem(4);
            left: 0;
            width: rem(50);
            height: rem(2);
            background-color: $color20;
        }
    }
    &.-smaller{
        text-transform: uppercase;
        letter-spacing: rem(1);
        font-size: rem(15);
    }
    &.-primary{
        color: $color25;
    }
    &.-secondary{
        color: $color20;
    }
    &.-terciary{
        color: $color36;
    }
    &.-poppins {
        font-family: $poppins;
    }
    &.-abril {
        font-family: $abriltext;
    }

    @media screen and (max-width: $screen-xs-max){
        &.-biggest{
            font-size: rem(24);
        }
        &.-medium{
            font-size: rem(18);
        }
        &.-lined {
            padding-left: rem(43);
            &:before {
                width: rem(32);
            }
        }
        &.-smaller{
            font-size: rem(12);
        }
    }
}