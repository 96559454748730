// Pagination
.pagination {
    > li > a,
    > li > span {
        padding: 0;
        line-height: rem(40);
        text-align: center;
        width: rem(40);
        height: rem(40);
        color: $color10;
        font-weight: 600;
        margin: rem(0 5);
        border-radius: rem(0);
        background-color: $color25;
        border: none;
    }
}
.pagination > .active > a, .pagination > .active > a:hover, .pagination > .active > a:focus, .pagination > .active > span, .pagination > .active > span:hover, .pagination > .active > span:focus {
    z-index: 3;
    color: $color10;
    background-color: $color20;
    cursor: default;
    border: none;
}
.pagination > li:first-child > a, .pagination > li:first-child > span {
    margin-left: 0;
    // border-bottom-left-radius: rem(50);
    // border-top-left-radius: rem(50);
    
}

.pagination > li:last-child > a, .pagination > li:last-child > span {
    margin-left: 0;
    // border-bottom-right-radius: rem(50);
    // border-top-right-radius: rem(50);
}
