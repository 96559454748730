.slide-pagination {
    .swiper-prev {
        left: 0;
    }
    .swiper-next {
        right: 0;
    }

    &.-circled {
        .swiper-prev,
        .swiper-next {
            position: absolute;
            width: rem(40);
            height: rem(40);
            border-radius: rem(40);
            font-size: rem(13);
            transition: all ease .4s;
            cursor: pointer;
            display: inline-block;

            &:hover {
                border-width: rem(1.2);
                border-style: solid;
                border-color: $color20;
            }

            &:before,
            &:after {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }

    &.-terciary {
        color: $color36;
        
        .swiper-prev,
        .swiper-next { 
            border-color: $color36;
            border-style: solid;
            border-width: rem(1.2);
        }

        &:hover {
            border-color: $color20;
           color: $color20;
        }
    }

    &.-center {
        position: absolute;
        z-index: 2;
        width: 100%;
        top: 50%;
        transform: translateY(-50%);
        height: rem(1);
    }
}