@-ms-viewport   { width: device-width; }
@-o-viewport    { width: device-width; }
@viewport       { width: device-width; }

html {
  font-size: $font-size-base;
}

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: none;
}

a,
button,
input[type="button"] {
  outline: none !important;
  transition: all .4s ease !important;
  &:hover,
  &:visited,
  &:focus {
    text-decoration: none;
  }
}

a[href^=tel]
a[href^=email] {
  color: inherit;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0;
}

ul {
  list-style: none;
  padding-left: 0;
}

.clear {
  clear: both;
}