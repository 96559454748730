// Menu
.menu {
  transition: all .4s ease;
  overflow: initial;
  // width: calc(100% - 120px);

  .link {
      font-size: rem(13);
      cursor: pointer;
      text-transform: uppercase;
      color: $color10;
      font-weight: 600;

      &.-active {
          color: $color20;
      }

      &:hover {
          color: $color20;
      }
  }

  @media screen and (min-width: $screen-lg - 1) {
      float: right;
      margin-left: rem(50);

      .list {
          .item {
              float: left;
              display: inline-block;
              margin-right: rem(20);
              height: rem(85);
              line-height: rem(85);
              position: relative;
          }
      }
  }

  @media screen and (max-width: $screen-lg - 1) {
      box-shadow: rem(1 0 12 0) rgba($color20, .25);
      background-color: $color25;
      visibility: hidden;
      width: rem(280);
      height: calc(100vh - 70px);
      display: block;
      position: fixed;
      z-index: 1499;
      top: rem(70);
      left: 0;
      overflow-y: auto;
      transform: translateX(-100%);
      padding-top: rem(15);
      padding-bottom: rem(15);

      &.-show {
          visibility: visible;
          transform: translateX(0);
      }

      .link {
          color: $color10;
          display: block;
          font-size: rem(12);
          padding: rem(12 30);
          font-size: rem(14);
          font-weight: 600;
          letter-spacing: 1px;

          &:hover {
              color: $color30;
          }

      }
  }
}
