// Colors
.f {
  // White
  &-c10 {
    color: $color10 !important;
  }

  // Black
  &-c19 {
    color: $color19 !important;
  }
}
