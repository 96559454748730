.link {
    &.-default {
        text-transform: uppercase;
        font-size: rem(14);
        letter-spacing: rem(1);

        &:before,
        &:after {
            vertical-align: middle;
        }

        &:after {
            margin-left: rem(11);
        }
        &:before {
            margin-right: rem(11);
        }
    }
    
    &.-primary {
        color: $color20;
        &:hover {
            color: $color25;
        }
    }
    &.-terciary {
        color: $color36;
        &:hover {
            color: $color20;
        }
    }
    &.-block {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
    }
}