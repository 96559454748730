// Hover
.hover {
    &-scale {
        .image {
            transition: all .50s ease;
        }
        &:hover {
            .image {
                transition: all .50s ease;
                transform: scale(1.1, 1.1);
            }
        }
    }
}
