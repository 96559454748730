.tab {
    &.-prog {
        .tab-header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-bottom: rem(20);

            .item {
                cursor: pointer;
                text-align: center;
                flex: 1;
                padding: rem(30 10);
                background-color: rgba($color25, .15);
                border: 2px solid white;
                color: $color25;
                position: relative;

                &::before {
                    content: '';
                    position: absolute;
                    width: rem(15);
                    height: rem(15);
                    background-color: transparent;
                    left: 50%;
                    bottom: rem(-7);
                    transform: translateX(-50%) rotate(45deg);
                }

                &:hover {
                    background-color: rgba($color25, .4);
                }
                &.active {
                    background-color: $color25;
                    color: $color10;
                    cursor: inherit;
                    position: relative;

                    &::before {
                        background-color: $color25;
                    }
                }
            }
        }
        .tab-content {
            overflow-x:auto;
            display: none;

            &.active {
                display: block;
            }
        }
        @media screen and (max-width: $screen-xs-max){
            .tab-header {
                flex-direction: column;
                .item {
                    width: 100%;
                    &::before {
                        display: none;
                    }

                }
            }
        }
    }
}