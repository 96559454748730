// Font
.font {
    &-bold {
        font-weight: 700;
    }
    &-black {
        font-weight: 900;
    }
    &-italic {
        font-style: italic;
    }
}
