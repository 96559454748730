// Font
.f {
  // Font Family

  // Font Variations
  &-light {
    font-weight: 300;
  }

  &-semibold {
    font-weight: 600;
  }

  &-bold {
    font-weight: 700;
  }

  &-black {
    font-weight: 900;
  }

  &-italic {
    font-style: italic;
  }

  &-upper{
    text-transform: uppercase;
  }

  &-poppins {
    font-family: $poppins;
  }

  &-abril {
    font-family: $abriltext;
  }

  // Font effects
}
