// Section
.section {
  position: relative;
  z-index: 3;
  padding-top: rem(100);
  padding-bottom: rem(100);

  .section-heading {
    .title {
      &.-top{
        margin-bottom: rem(60);
      }
      &.-biggest {
        margin-bottom: rem(30);
        font-weight: 600;
      }
      &.-lined {
        max-width: rem(500);
      }
    }
    .text {
      margin-top: rem(20);
      margin-bottom: rem(20);
      font-size: rem(14);
      color: rgba($color36, .7);
      letter-spacing: rem(1);
    }
  }

  @media screen and (max-width: $screen-xs-max){
    padding-top: rem(50);
    padding-bottom: rem(50);
  }
  
  &.-intro {
    padding-top: 0;
    height: 100vh;
    text-align: center;
    position: relative;

    &:before {
      content: '';
      height: rem(50);
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      background-color: $color10;
    }

    &.-default {
      max-height: rem(500);
      height: 100vh;
      min-height: rem(300);
      
      .section-heading {
        padding-top: rem(80);
        max-height: rem(500);
        min-height: rem(300);

        .title {
          font-weight: 700;
          text-transform: uppercase;
          font-size: rem(45);
        }
      }
      &:before {
        display: none;
      }

      @media screen and (max-width: $screen-xs-max){
        .section-heading {
          .title {
            font-size: rem(20);
          }
        }
      }
    }

    .section-heading {
      height: 100vh;
      .title {
        color: $color10;

        .initials {
          display: block;
          font-size: rem(30);
          font-weight: 700;
        }

        .name {
          font-size: rem(40);
          letter-spacing: rem(3);
          font-weight: 600;
          margin-top: rem(5);
          display: inline-block;
          max-width: rem(600);
          position: relative;

          &:before {
            content: '';
            width: rem(70);
            height: rem(3);
            background: $color20;
            position: absolute;
            bottom: rem(-20);
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
      .text {
        color: $color10;
      }
      .tema {
        max-width: rem(450);
        margin: 0 auto;
        display: block;
        margin-top: rem(40);
        font-size: rem(20);
        font-weight: 500;
      }
      .date {
        text-align: left;
        margin-left: rem(40);
        position: relative;
        display: inline-block;
        padding-left: rem(10);
        // border-left: 3px solid $color20;
        margin-top: rem(50);

        &::before {
          position: absolute;
          top: rem(3);
          left: rem(-45);
          font-size: rem(35);
        }
      }
    }

    .section-footing {
      position: absolute;
      bottom: rem(20);
      width: 100%;
    }
    @media screen and (max-width: $screen-xs-max){
      .section-heading {
        .title {
          color: $color10;
  
          .initials {
            font-size: rem(17);
          }
          .name {
            font-size: rem(20);
          }
          .tema {
            font-size: rem(15);
          }
        }
      }
      .section-footing {
        position: relative;
        bottom: rem(75);
      }
    }
    
  }

  &.-home-about {
    padding-top: rem(120);
    .section-heading {
      .logo {
        width: 100%;
        max-width: rem(380);
        margin-bottom: rem(30);
      }
      .title.-biggest {
        font-size: rem(50);
      }
    }
    .section-content {
      
      .text {
        text-align: justify;
        color: $color15;
        line-height: 1.5;
      }
      .btn {
        margin-top: rem(30);
      }
    }
    @media screen and (max-width: $screen-xs-max){
      padding-top: rem(200);
      .section-heading {
        .title.-biggest {
          font-size: rem(25);
        }
      }
    }
  }

  &.-prog {
    padding-top: rem(120);
    .section-heading {
      .title.-biggest {
        font-size: rem(50);
      }
    }
    .section-content {
      
      .text {
        text-align: justify;
        color: $color15;
        line-height: 1.5;
      }
      .btn {
        margin-top: rem(30);
      }
    }
    @media screen and (max-width: $screen-xs-max){
      padding-top: rem(50);
      .section-heading {
        .title.-biggest {
          font-size: rem(25);
        }
      }
    }
  }

  &.-cronogram{
    background-color: $color25;
    .section-heading {
      .title {
        color: $color10;
      }
    }
  }

  &.-speakers {
    background-color: $color12;
    .section-heading {
      margin-bottom: rem(50);
    }
  }

  &.-location {
    background-color: $color40;
    color: $color10;
    padding-top: rem(80);
    padding-bottom: rem(60);
    .title {
      margin-bottom: rem(20);
    }
    .link {
      display: block;
      color: rgba($color10, .8);
      &:hover {
        color: $color10;
      }
    }
    .util {
      margin-bottom: rem(20);
      color: rgba($color12, .8);
      font-size: rem(14);
      letter-spacing: rem(1);
    }
  }

  &.-contact {
    position: relative;
    padding-bottom: 0;
    .form {
      .title {
        margin-bottom: rem(30);
      }
    }
  }

  &.-news {
    background-color: rgba($color15, .1);
  }

  &.-activities {}
  .collapsable {
    .list.-attrs {
      margin-bottom: rem(15);
      .item {
        width: rem(120);
        display: inline-block;
        vertical-align: top;
        margin-right: rem(10);
      }
    }
  }
  &.-organization {
    .section-content {
      .block {
        margin-bottom: rem(35);
        .title.-block {
          margin-bottom: rem(20);
        }
      }
    }
  }
}
