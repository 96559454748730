// Colors
$color10:   #ffffff;
$color12:   #f0f0f0;
$color15:   #797979;
$color17:   #313131;
$color19:   #000000;
$color20:   #158ce0;
$color21:   #3d68ac;
$color211:   #284a7e;
$color25:   #1c3e7d;
$color28:   #0f2c62;
$color30:   #ee9420;
$color300:   #cf7e1b;
$color33:   #f26522;
$color333:   #be4e1a;
$color36:   #4d301b;
$color40:   #242d39;
$color41:   #1d2631;

// Status Colors
$success:   #8bc34a;
$danger:    #f44336;
$warning:   #ffc107;
$info:      #03a9f4;

// Social Colors
$facebook:  #39579b;
$twitter:   #588dc9;
$instagram: #8a3ab9;
$youtube:   #bb0000;
$google:    #dd4b39;
$linkedin:  #0079b8;
$whatsapp:  #4dc247;
