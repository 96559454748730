.collapsable {
    border: rem(3) solid $color25;
    .heading {
        font-size: rem(20);
        font-weight: 600;
        position: relative;
        cursor: pointer;        
        transition: all .4s ease;
        padding-right: rem(40);
        margin-bottom: 0 !important;
        border-top: rem(3) solid $color25;
        padding: rem(20);
        padding-right: rem(50);

        &:first-child {
            border-color: transparent;
        }

        .icon {
            position: absolute;
            right: rem(20);
            top: 50%;
            transform: translateY(-50%);
        }

        .local {
            color: $color20;
            font-weight: 600;
            letter-spacing: rem(2);
            font-size: rem(14);
            text-transform: uppercase;
            &:before,
            &.after {
                display: none;
            }
        }
    
    }
    .content {
        margin-bottom: rem(10);
        padding: rem(20);
        padding-right: rem(50);
        padding-top: 0;

        .block {
            margin-top: rem(15);
            .title.-block {
                text-transform: uppercase;
                letter-spacing: rem(2);
                color: $color20;
                font-weight: 600;
                margin-bottom: rem(15);
            }
        }
        
    }
    @media screen and (max-width: $screen-sm-max) {
        .content {
        }
    }
}
