.over {
    &-shine {
        overflow: hidden;
        position: relative;

        &::before {
            position: absolute;
            top: 0;
            left: -75%;
            z-index: 2;
            display: block;
            content: '';
            width: 50%;
            height: 100%;
            background: -webkit-linear-gradient(left, rgba($color10,0) 0%, rgba($color10,.3) 100%);
            background: linear-gradient(to right, rgba($color10,0) 0%, rgba($color10,.3) 100%);
            -webkit-transform: skewX(-25deg);
            transform: skewX(-25deg);
        }
        &:hover::before {
            -webkit-animation: shine 1.2s;
            animation: shine 1.2s;
        }
        @-webkit-keyframes shine {
            100% {
                left: 125%;
            }
        }
        @keyframes shine {
            100% {
                left: 125%;
            }
        }
        
    }
}