.quick-access {
  .item {
    .link {
      transition: all ease .3s;
      text-transform: uppercase;
      color: $color10;
      letter-spacing: rem(2);
      font-size: rem(14);
      font-weight: 600;
      height: rem(150);
      display: table;
      width: calc(100% + 1px);
      padding: rem(0 40);

      &:hover {
          transform: translateY(-20px);
      }
    }
    span {
        display: table-cell;
        vertical-align: middle;
    }
  }
  .-one {
    background-color: $color40;
    border-top-left-radius: rem(5);
    border-bottom-left-radius: rem(5);
    &:hover {
      background-color: $color41;
    }
  }
  .-two {
    background-color: $color21;
    &:hover {
      background-color: $color211;
    }
  }
  .-three {
    background-color: $color33;
    &:hover {
      background-color: $color333;
    }
  }
  .-four {
    background-color: $color30;
    border-top-right-radius: rem(5);
    border-bottom-right-radius: rem(5);
    &:hover {
      background-color: $color300;
    }
  }

  @media screen and (max-width: $screen-xs-max){
    .item {
      .link {
        height: auto;
        padding: rem(20 40);
        border-radius: 0;
      }
      span {
          font-size: rem(12);
          display: table-cell;
          vertical-align: middle;
      }
    }
  }

}