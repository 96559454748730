// PX to REM
@function rem($values) {
  $list: ();

  @each $value in $values {
    @if $value == 'auto' {
      $list: append($list, $value);
    } @else {
      $list: append($list, ($value/16) + rem);
    }
  }

  @return $list();
}
